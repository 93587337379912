const banner = document.querySelector('[data-cy="header-banner"]')
const button = document.querySelector('[data-cy="header-banner-dismiss"]')

// Show banner if it hasn't been dismissed
if (localStorage.getItem('bannerDismissed') !== 'true') {
  banner.classList.remove('banner-dismissed')
  window.dispatchEvent(
    new CustomEvent('banner:visible', {
      detail: true,
    }),
  )
}

// Dismiss the banner when the button is clicked
button.addEventListener('click', (event) => {
  banner.classList.add('banner-dismissed')
  localStorage.setItem('bannerDismissed', 'true')
  window.dispatchEvent(
    new CustomEvent('banner:dismissed', {
      detail: true,
    }),
  )
  event.stopPropagation()
})
